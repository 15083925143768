/* src/App.css */
.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  box-sizing: border-box;
}

.paper {
  width: 8.5in;
  height: 11in;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  margin: 20px 0;
  overflow: auto;
}

.recipe-display {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.recipe-display h1 {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.recipe-display h2 {
  font-size: 1.2em;
  color: #5a5a5a;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 5px;
  margin-top: 20px;
}

.recipe-display ul {
  list-style-type: none;
  padding: 0;
}

.recipe-display li {
  margin-bottom: 5px;
}

.recipe-display .ingredient,
.recipe-display .step {
  margin-left: 20px;
}

.recipe-display .ingredient {
  list-style-type: disc;
  margin-left: 40px; /* Additional indentation */
}

.recipe-display .step {
  list-style-type: decimal;
}

.recipe-display input[type="checkbox"] {
  transform: scale(1);
  margin-right: 5px;
}

.input-panel {
  width: 320px;
  padding: 15px;
  background-color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  color: white;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #bbb;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #555;
  background-color: #444;
  color: white;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.form-group .add-button {
  padding: 8px 15px;
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.form-group .add-button:hover {
  background-color: #777;
}

.form-group .add-button::before {
  content: '+';
  margin-right: 5px;
  font-size: 18px;
}

.print-button {
  padding: 8px 15px;
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  align-self: flex-end;
}

.print-button:hover {
  background-color: #777;
}

.collapsible-section {
  margin-bottom: 10px;
}

.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #444;
  padding: 10px;
  cursor: pointer;
}

.collapsible-header h3 {
  margin: 0;
  font-size: 1em;
}

.collapsible-header .toggle-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
}

.collapsible-content {
  padding: 10px;
  background-color: #555;
}

@media print {
  .input-panel {
    display: none;
  }
  .print-button {
    display: none;
  }
  .paper {
    border: none;
    box-shadow: none;
    margin: 0;
  }
}